@use "components/variables" as *;
@use "components/mixins" as *;

//@use "~intl-tel-input/src/css/intlTelInput";
:root {
    --iti-hover-color: rgba(0, 0, 0, 0.05);
    --iti-border-color: #ccc;
    --iti-dialcode-color: #999;
    --iti-dropdown-bg: white;
    --iti-spacer-horizontal: 8px;
    --iti-flag-height: 12px;
    --iti-flag-width: 16px;
    --iti-border-width: 1px;
    --iti-arrow-height: 4px;
    --iti-arrow-width: 6px;
    --iti-triangle-border: calc(var(--iti-arrow-width) / 2);
    --iti-arrow-padding: 6px;
    --iti-arrow-color: #555;
    --iti-path-flags-1x: url("/images/intlphone/flags.webp");
    --iti-path-flags-2x: url("/images/intlphone/flags@2x.webp");
    --iti-path-globe-1x: url("/images/intlphone/globe.webp");
    --iti-path-globe-2x: url("/images/intlphone/globe@2x.webp");
    --iti-flag-sprite-width: 3904px;
    --iti-flag-sprite-height: 12px;
    --iti-mobile-popup-margin: 30px;
}

.iti {
    position: relative;
    display: inline-block;
}
.iti * {
    box-sizing: border-box;
}
.iti__hide {
    display: none;
}
.iti__v-hide {
    visibility: hidden;
}
.iti__a11y-text {
    width: 1px;
    height: 1px;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    position: absolute;
}
.iti input.iti__tel-input,
.iti input.iti__tel-input[type=text],
.iti input.iti__tel-input[type=tel] {
    position: relative;
    z-index: 0;
    margin: 0 !important;
}
.iti__country-container {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: var(--iti-border-width);
}
.iti__selected-country {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    background: none;
    border: 0;
    margin: 0;
    padding: 0;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    border-radius: 0;
    font-weight: inherit;
    line-height: inherit;
    text-decoration: none;
}
.iti__selected-country-primary {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 var(--iti-arrow-padding) 0 var(--iti-spacer-horizontal);
}
.iti__arrow {
    margin-left: var(--iti-arrow-padding);
    width: 0;
    height: 0;
    border-left: var(--iti-triangle-border) solid transparent;
    border-right: var(--iti-triangle-border) solid transparent;
    border-top: var(--iti-arrow-height) solid var(--iti-arrow-color);
}
[dir=rtl] .iti__arrow {
    margin-right: var(--iti-arrow-padding);
    margin-left: 0;
}
.iti__arrow--up {
    border-top: none;
    border-bottom: var(--iti-arrow-height) solid var(--iti-arrow-color);
}
.iti__dropdown-content {
    border-radius: 3px;
    background-color: var(--iti-dropdown-bg);
}
.iti--inline-dropdown .iti__dropdown-content {
    position: absolute;
    z-index: 2;
    margin-top: 3px;
    margin-left: calc(var(--iti-border-width) * -1);
    border: var(--iti-border-width) solid var(--iti-border-color);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}
.iti__search-input {
    width: 100%;
    border-width: 0;
    border-radius: 3px;
}
.iti__search-input + .iti__country-list {
    border-top: 1px solid var(--iti-border-color);
}
.iti__country-list {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
.iti--inline-dropdown .iti__country-list {
    max-height: 185px;
}
.iti--flexible-dropdown-width .iti__country-list {
    white-space: nowrap;
}
@media (max-width: 500px) {
    .iti--flexible-dropdown-width .iti__country-list {
        white-space: normal;
    }
}
.iti__country {
    display: flex;
    align-items: center;
    padding: 8px var(--iti-spacer-horizontal);
    outline: none;
}
.iti__dial-code {
    color: var(--iti-dialcode-color);
}
.iti__country.iti__highlight {
    background-color: var(--iti-hover-color);
}
.iti__country-list .iti__flag, .iti__country-name {
    margin-right: var(--iti-spacer-horizontal);
}
[dir=rtl] .iti__country-list .iti__flag, [dir=rtl] .iti__country-name {
    margin-right: 0;
    margin-left: var(--iti-spacer-horizontal);
}
.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])):hover, .iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])):hover button {
    cursor: pointer;
}
.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country-primary:hover,
.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country:has(+ .iti__dropdown-content:hover) .iti__selected-country-primary {
    background-color: var(--iti-hover-color);
}
.iti .iti__selected-dial-code {
    margin-left: 4px;
}
[dir=rtl] .iti .iti__selected-dial-code {
    margin-left: 0;
    margin-right: 4px;
}
.iti--container {
    position: fixed;
    top: -1000px;
    left: -1000px;
    z-index: 1060;
    padding: var(--iti-border-width);
}
.iti--container:hover {
    cursor: pointer;
}

.iti--fullscreen-popup.iti--container {
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    padding: var(--iti-mobile-popup-margin);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.iti--fullscreen-popup .iti__dropdown-content {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    position: relative;
}
.iti--fullscreen-popup .iti__country {
    padding: 10px 10px;
    line-height: 1.5em;
}

.iti__flag {
    --iti-flag-offset: 100px;
    height: var(--iti-flag-height);
    width: var(--iti-flag-width);
    border-radius: 1px;
    box-shadow: 0px 0px 1px 0px #888;
    background-image: var(--iti-path-flags-1x);
    background-repeat: no-repeat;
    background-position: var(--iti-flag-offset) 0;
    background-size: var(--iti-flag-sprite-width) var(--iti-flag-sprite-height);
}

.iti__ac {
    --iti-flag-offset: 0px;
}

.iti__ad {
    --iti-flag-offset: -16px;
}

.iti__ae {
    --iti-flag-offset: -32px;
}

.iti__af {
    --iti-flag-offset: -48px;
}

.iti__ag {
    --iti-flag-offset: -64px;
}

.iti__ai {
    --iti-flag-offset: -80px;
}

.iti__al {
    --iti-flag-offset: -96px;
}

.iti__am {
    --iti-flag-offset: -112px;
}

.iti__ao {
    --iti-flag-offset: -128px;
}

.iti__ar {
    --iti-flag-offset: -144px;
}

.iti__as {
    --iti-flag-offset: -160px;
}

.iti__at {
    --iti-flag-offset: -176px;
}

.iti__au {
    --iti-flag-offset: -192px;
}

.iti__aw {
    --iti-flag-offset: -208px;
}

.iti__ax {
    --iti-flag-offset: -224px;
}

.iti__az {
    --iti-flag-offset: -240px;
}

.iti__ba {
    --iti-flag-offset: -256px;
}

.iti__bb {
    --iti-flag-offset: -272px;
}

.iti__bd {
    --iti-flag-offset: -288px;
}

.iti__be {
    --iti-flag-offset: -304px;
}

.iti__bf {
    --iti-flag-offset: -320px;
}

.iti__bg {
    --iti-flag-offset: -336px;
}

.iti__bh {
    --iti-flag-offset: -352px;
}

.iti__bi {
    --iti-flag-offset: -368px;
}

.iti__bj {
    --iti-flag-offset: -384px;
}

.iti__bl {
    --iti-flag-offset: -400px;
}

.iti__bm {
    --iti-flag-offset: -416px;
}

.iti__bn {
    --iti-flag-offset: -432px;
}

.iti__bo {
    --iti-flag-offset: -448px;
}

.iti__bq {
    --iti-flag-offset: -464px;
}

.iti__br {
    --iti-flag-offset: -480px;
}

.iti__bs {
    --iti-flag-offset: -496px;
}

.iti__bt {
    --iti-flag-offset: -512px;
}

.iti__bw {
    --iti-flag-offset: -528px;
}

.iti__by {
    --iti-flag-offset: -544px;
}

.iti__bz {
    --iti-flag-offset: -560px;
}

.iti__ca {
    --iti-flag-offset: -576px;
}

.iti__cc {
    --iti-flag-offset: -592px;
}

.iti__cd {
    --iti-flag-offset: -608px;
}

.iti__cf {
    --iti-flag-offset: -624px;
}

.iti__cg {
    --iti-flag-offset: -640px;
}

.iti__ch {
    --iti-flag-offset: -656px;
}

.iti__ci {
    --iti-flag-offset: -672px;
}

.iti__ck {
    --iti-flag-offset: -688px;
}

.iti__cl {
    --iti-flag-offset: -704px;
}

.iti__cm {
    --iti-flag-offset: -720px;
}

.iti__cn {
    --iti-flag-offset: -736px;
}

.iti__co {
    --iti-flag-offset: -752px;
}

.iti__cr {
    --iti-flag-offset: -768px;
}

.iti__cu {
    --iti-flag-offset: -784px;
}

.iti__cv {
    --iti-flag-offset: -800px;
}

.iti__cw {
    --iti-flag-offset: -816px;
}

.iti__cx {
    --iti-flag-offset: -832px;
}

.iti__cy {
    --iti-flag-offset: -848px;
}

.iti__cz {
    --iti-flag-offset: -864px;
}

.iti__de {
    --iti-flag-offset: -880px;
}

.iti__dj {
    --iti-flag-offset: -896px;
}

.iti__dk {
    --iti-flag-offset: -912px;
}

.iti__dm {
    --iti-flag-offset: -928px;
}

.iti__do {
    --iti-flag-offset: -944px;
}

.iti__dz {
    --iti-flag-offset: -960px;
}

.iti__ec {
    --iti-flag-offset: -976px;
}

.iti__ee {
    --iti-flag-offset: -992px;
}

.iti__eg {
    --iti-flag-offset: -1008px;
}

.iti__eh {
    --iti-flag-offset: -1024px;
}

.iti__er {
    --iti-flag-offset: -1040px;
}

.iti__es {
    --iti-flag-offset: -1056px;
}

.iti__et {
    --iti-flag-offset: -1072px;
}

.iti__fi {
    --iti-flag-offset: -1088px;
}

.iti__fj {
    --iti-flag-offset: -1104px;
}

.iti__fk {
    --iti-flag-offset: -1120px;
}

.iti__fm {
    --iti-flag-offset: -1136px;
}

.iti__fo {
    --iti-flag-offset: -1152px;
}

.iti__fr {
    --iti-flag-offset: -1168px;
}

.iti__ga {
    --iti-flag-offset: -1184px;
}

.iti__gb {
    --iti-flag-offset: -1200px;
}

.iti__gd {
    --iti-flag-offset: -1216px;
}

.iti__ge {
    --iti-flag-offset: -1232px;
}

.iti__gf {
    --iti-flag-offset: -1248px;
}

.iti__gg {
    --iti-flag-offset: -1264px;
}

.iti__gh {
    --iti-flag-offset: -1280px;
}

.iti__gi {
    --iti-flag-offset: -1296px;
}

.iti__gl {
    --iti-flag-offset: -1312px;
}

.iti__gm {
    --iti-flag-offset: -1328px;
}

.iti__gn {
    --iti-flag-offset: -1344px;
}

.iti__gp {
    --iti-flag-offset: -1360px;
}

.iti__gq {
    --iti-flag-offset: -1376px;
}

.iti__gr {
    --iti-flag-offset: -1392px;
}

.iti__gt {
    --iti-flag-offset: -1408px;
}

.iti__gu {
    --iti-flag-offset: -1424px;
}

.iti__gw {
    --iti-flag-offset: -1440px;
}

.iti__gy {
    --iti-flag-offset: -1456px;
}

.iti__hk {
    --iti-flag-offset: -1472px;
}

.iti__hn {
    --iti-flag-offset: -1488px;
}

.iti__hr {
    --iti-flag-offset: -1504px;
}

.iti__ht {
    --iti-flag-offset: -1520px;
}

.iti__hu {
    --iti-flag-offset: -1536px;
}

.iti__id {
    --iti-flag-offset: -1552px;
}

.iti__ie {
    --iti-flag-offset: -1568px;
}

.iti__il {
    --iti-flag-offset: -1584px;
}

.iti__im {
    --iti-flag-offset: -1600px;
}

.iti__in {
    --iti-flag-offset: -1616px;
}

.iti__io {
    --iti-flag-offset: -1632px;
}

.iti__iq {
    --iti-flag-offset: -1648px;
}

.iti__ir {
    --iti-flag-offset: -1664px;
}

.iti__is {
    --iti-flag-offset: -1680px;
}

.iti__it {
    --iti-flag-offset: -1696px;
}

.iti__je {
    --iti-flag-offset: -1712px;
}

.iti__jm {
    --iti-flag-offset: -1728px;
}

.iti__jo {
    --iti-flag-offset: -1744px;
}

.iti__jp {
    --iti-flag-offset: -1760px;
}

.iti__ke {
    --iti-flag-offset: -1776px;
}

.iti__kg {
    --iti-flag-offset: -1792px;
}

.iti__kh {
    --iti-flag-offset: -1808px;
}

.iti__ki {
    --iti-flag-offset: -1824px;
}

.iti__km {
    --iti-flag-offset: -1840px;
}

.iti__kn {
    --iti-flag-offset: -1856px;
}

.iti__kp {
    --iti-flag-offset: -1872px;
}

.iti__kr {
    --iti-flag-offset: -1888px;
}

.iti__kw {
    --iti-flag-offset: -1904px;
}

.iti__ky {
    --iti-flag-offset: -1920px;
}

.iti__kz {
    --iti-flag-offset: -1936px;
}

.iti__la {
    --iti-flag-offset: -1952px;
}

.iti__lb {
    --iti-flag-offset: -1968px;
}

.iti__lc {
    --iti-flag-offset: -1984px;
}

.iti__li {
    --iti-flag-offset: -2000px;
}

.iti__lk {
    --iti-flag-offset: -2016px;
}

.iti__lr {
    --iti-flag-offset: -2032px;
}

.iti__ls {
    --iti-flag-offset: -2048px;
}

.iti__lt {
    --iti-flag-offset: -2064px;
}

.iti__lu {
    --iti-flag-offset: -2080px;
}

.iti__lv {
    --iti-flag-offset: -2096px;
}

.iti__ly {
    --iti-flag-offset: -2112px;
}

.iti__ma {
    --iti-flag-offset: -2128px;
}

.iti__mc {
    --iti-flag-offset: -2144px;
}

.iti__md {
    --iti-flag-offset: -2160px;
}

.iti__me {
    --iti-flag-offset: -2176px;
}

.iti__mf {
    --iti-flag-offset: -2192px;
}

.iti__mg {
    --iti-flag-offset: -2208px;
}

.iti__mh {
    --iti-flag-offset: -2224px;
}

.iti__mk {
    --iti-flag-offset: -2240px;
}

.iti__ml {
    --iti-flag-offset: -2256px;
}

.iti__mm {
    --iti-flag-offset: -2272px;
}

.iti__mn {
    --iti-flag-offset: -2288px;
}

.iti__mo {
    --iti-flag-offset: -2304px;
}

.iti__mp {
    --iti-flag-offset: -2320px;
}

.iti__mq {
    --iti-flag-offset: -2336px;
}

.iti__mr {
    --iti-flag-offset: -2352px;
}

.iti__ms {
    --iti-flag-offset: -2368px;
}

.iti__mt {
    --iti-flag-offset: -2384px;
}

.iti__mu {
    --iti-flag-offset: -2400px;
}

.iti__mv {
    --iti-flag-offset: -2416px;
}

.iti__mw {
    --iti-flag-offset: -2432px;
}

.iti__mx {
    --iti-flag-offset: -2448px;
}

.iti__my {
    --iti-flag-offset: -2464px;
}

.iti__mz {
    --iti-flag-offset: -2480px;
}

.iti__na {
    --iti-flag-offset: -2496px;
}

.iti__nc {
    --iti-flag-offset: -2512px;
}

.iti__ne {
    --iti-flag-offset: -2528px;
}

.iti__nf {
    --iti-flag-offset: -2544px;
}

.iti__ng {
    --iti-flag-offset: -2560px;
}

.iti__ni {
    --iti-flag-offset: -2576px;
}

.iti__nl {
    --iti-flag-offset: -2592px;
}

.iti__no {
    --iti-flag-offset: -2608px;
}

.iti__np {
    --iti-flag-offset: -2624px;
}

.iti__nr {
    --iti-flag-offset: -2640px;
}

.iti__nu {
    --iti-flag-offset: -2656px;
}

.iti__nz {
    --iti-flag-offset: -2672px;
}

.iti__om {
    --iti-flag-offset: -2688px;
}

.iti__pa {
    --iti-flag-offset: -2704px;
}

.iti__pe {
    --iti-flag-offset: -2720px;
}

.iti__pf {
    --iti-flag-offset: -2736px;
}

.iti__pg {
    --iti-flag-offset: -2752px;
}

.iti__ph {
    --iti-flag-offset: -2768px;
}

.iti__pk {
    --iti-flag-offset: -2784px;
}

.iti__pl {
    --iti-flag-offset: -2800px;
}

.iti__pm {
    --iti-flag-offset: -2816px;
}

.iti__pr {
    --iti-flag-offset: -2832px;
}

.iti__ps {
    --iti-flag-offset: -2848px;
}

.iti__pt {
    --iti-flag-offset: -2864px;
}

.iti__pw {
    --iti-flag-offset: -2880px;
}

.iti__py {
    --iti-flag-offset: -2896px;
}

.iti__qa {
    --iti-flag-offset: -2912px;
}

.iti__re {
    --iti-flag-offset: -2928px;
}

.iti__ro {
    --iti-flag-offset: -2944px;
}

.iti__rs {
    --iti-flag-offset: -2960px;
}

.iti__ru {
    --iti-flag-offset: -2976px;
}

.iti__rw {
    --iti-flag-offset: -2992px;
}

.iti__sa {
    --iti-flag-offset: -3008px;
}

.iti__sb {
    --iti-flag-offset: -3024px;
}

.iti__sc {
    --iti-flag-offset: -3040px;
}

.iti__sd {
    --iti-flag-offset: -3056px;
}

.iti__se {
    --iti-flag-offset: -3072px;
}

.iti__sg {
    --iti-flag-offset: -3088px;
}

.iti__sh {
    --iti-flag-offset: -3104px;
}

.iti__si {
    --iti-flag-offset: -3120px;
}

.iti__sj {
    --iti-flag-offset: -3136px;
}

.iti__sk {
    --iti-flag-offset: -3152px;
}

.iti__sl {
    --iti-flag-offset: -3168px;
}

.iti__sm {
    --iti-flag-offset: -3184px;
}

.iti__sn {
    --iti-flag-offset: -3200px;
}

.iti__so {
    --iti-flag-offset: -3216px;
}

.iti__sr {
    --iti-flag-offset: -3232px;
}

.iti__ss {
    --iti-flag-offset: -3248px;
}

.iti__st {
    --iti-flag-offset: -3264px;
}

.iti__sv {
    --iti-flag-offset: -3280px;
}

.iti__sx {
    --iti-flag-offset: -3296px;
}

.iti__sy {
    --iti-flag-offset: -3312px;
}

.iti__sz {
    --iti-flag-offset: -3328px;
}

.iti__tc {
    --iti-flag-offset: -3344px;
}

.iti__td {
    --iti-flag-offset: -3360px;
}

.iti__tg {
    --iti-flag-offset: -3376px;
}

.iti__th {
    --iti-flag-offset: -3392px;
}

.iti__tj {
    --iti-flag-offset: -3408px;
}

.iti__tk {
    --iti-flag-offset: -3424px;
}

.iti__tl {
    --iti-flag-offset: -3440px;
}

.iti__tm {
    --iti-flag-offset: -3456px;
}

.iti__tn {
    --iti-flag-offset: -3472px;
}

.iti__to {
    --iti-flag-offset: -3488px;
}

.iti__tr {
    --iti-flag-offset: -3504px;
}

.iti__tt {
    --iti-flag-offset: -3520px;
}

.iti__tv {
    --iti-flag-offset: -3536px;
}

.iti__tw {
    --iti-flag-offset: -3552px;
}

.iti__tz {
    --iti-flag-offset: -3568px;
}

.iti__ua {
    --iti-flag-offset: -3584px;
}

.iti__ug {
    --iti-flag-offset: -3600px;
}

.iti__us {
    --iti-flag-offset: -3616px;
}

.iti__uy {
    --iti-flag-offset: -3632px;
}

.iti__uz {
    --iti-flag-offset: -3648px;
}

.iti__va {
    --iti-flag-offset: -3664px;
}

.iti__vc {
    --iti-flag-offset: -3680px;
}

.iti__ve {
    --iti-flag-offset: -3696px;
}

.iti__vg {
    --iti-flag-offset: -3712px;
}

.iti__vi {
    --iti-flag-offset: -3728px;
}

.iti__vn {
    --iti-flag-offset: -3744px;
}

.iti__vu {
    --iti-flag-offset: -3760px;
}

.iti__wf {
    --iti-flag-offset: -3776px;
}

.iti__ws {
    --iti-flag-offset: -3792px;
}

.iti__xk {
    --iti-flag-offset: -3808px;
}

.iti__ye {
    --iti-flag-offset: -3824px;
}

.iti__yt {
    --iti-flag-offset: -3840px;
}

.iti__za {
    --iti-flag-offset: -3856px;
}

.iti__zm {
    --iti-flag-offset: -3872px;
}

.iti__zw {
    --iti-flag-offset: -3888px;
}

.iti__globe {
    background-image: var(--iti-path-globe-1x);
    background-size: contain;
    background-position: right;
    box-shadow: none;
    height: 19px;
}

@media (min-resolution: 2x) {
    .iti__flag {
        background-image: var(--iti-path-flags-2x);
    }
    .iti__globe {
        background-image: var(--iti-path-globe-2x);
    }
}